// EngagementsPrerequisites.tsx
import React, { useEffect, useState } from "react";
import { Label, Description } from "@leafygreen-ui/typography";
import Checkbox from '@leafygreen-ui/checkbox'; // Import Checkbox
import TextInput from '@leafygreen-ui/text-input'; // Import TextInput

interface EngagementsPrerequisitesProps {
  selectedRunbook: string;
  registerValidation: (
    key: string,
    validateFunc: () => ValidationError
  ) => void;
  unregisterValidation: (key: string) => void;
  setPostDescription: (description: string) => void;
}

interface ValidationError {
  validation: boolean;
  message?: string;
}

// Define the type for each field
interface FieldConfig {
  mandatory: boolean;
  description: string;
  value: boolean | string;
}
// Define the structure of the configuration for each runbook
interface RunbookFieldConfig {
  [key: string]: FieldConfig;
}

// ------------------------------------------------------------------------
// ------ PreRequisite Configuration
// ------------------------------------------------------------------------

const fieldConfigTemplate: { [key: string]: RunbookFieldConfig } = {
  "Metric Review": {
    isM30orHigher: { mandatory: true, description: "Cluster is M30 or higher",  value: false},
    realPain: { mandatory: true, description: "Customer requested the review",  value: false},
    clusterName: { mandatory: true, description: "Cluster name", value: "" },
    atlasProjectLink: { mandatory: true, description: "Atlas Project link", value: ""}, 
    successPlan: { mandatory: false, description: "Success Plan Deck link", value: ""}, 
  },
  "Backup Optimization": {
    isM40orHigher: { mandatory: true, description: "Cluster is M40 or higher", value: false },
    csmOpenedTicket: { mandatory: true, description: "Backup spending amount is >40%", value: false },
    clusterName: { mandatory: true, description: "Cluster name", value: "" },
    atlasProjectLink: { mandatory: true, description: "Atlas Project link", value: ""}, 
    churnRisk: { mandatory: true, description: "Account team is aware that this will reduce the ARR", value: false },
  },
  "Cluster Optimization": {
    mSizerReader: { mandatory: true, description: "mSizer ready", value: false },
  },
  "Support Go-Live Loopback": {
    standardOrPremium: { mandatory: true, description: "Standard or Premium account", value: false },
  },
  "Technical Success Plan": {
    successPlanDone: { mandatory: true, description: "Success Plan has been completed", value: false },
  }
};

// ------------------------------------------------------------------------

const EngagementsPrerequisites: React.FC<EngagementsPrerequisitesProps> = ({
  registerValidation,
  unregisterValidation,
  selectedRunbook,
  setPostDescription,
}) => {
  const [fieldConfig, setFieldConfig] = useState(fieldConfigTemplate);

  useEffect(() => {
    const validationKey = `EngagementsPrerequisites_${selectedRunbook}`;
    registerValidation(validationKey, validateAndDescribePrerequisites);

    return () => unregisterValidation(validationKey);
  }, [selectedRunbook, fieldConfig]);

  // Generic handler to update field values in fieldConfig
  const handleFieldChange = (field: string, value: any) => {
    setFieldConfig((prevConfig) => ({
      ...prevConfig,
      [selectedRunbook]: {
        ...prevConfig[selectedRunbook],
        [field]: {
          ...prevConfig[selectedRunbook][field],
          value,
        },
      },
    }));
  };

  const validateAndDescribePrerequisites = (): ValidationError => {
    let description = `${selectedRunbook} Prerequisites:\n`;
    let allValid = true;
    let errorMessages: string[] = [];

    const config = fieldConfig[selectedRunbook] || {};

    // Iterate over each field in the config to validate and build the description
    for (const [fieldKey, field] of Object.entries(config)) {
      if (typeof field.value === "boolean") {
        description += field.value
          ? `- ${field.description}: Yes\n`
          : (field.mandatory &&
              ((allValid = false),
              errorMessages.push(`${field.description} is required.`)),
            `- ${field.description}: No\n`);
      } else if (typeof field.value === "string") {
        description += field.value
          ? `- ${field.description}: ${field.value}\n`
          : (field.mandatory &&
              ((allValid = false),
              errorMessages.push(`Please provide the ${field.description}.`)),
            `- ${field.description}: Not provided\n`);
      }
    }

    // Return validation result
    return allValid
      ? (setPostDescription(description), { validation: true })
      : { validation: false, message: errorMessages.join(" ") };
  };

  return (
    <div className="prerequisite">      
    
    <Label className="fieldMargin" id="description-label" htmlFor="descriptionTextArea">
        Runbook Prerequisites *
    </Label>
    <br />

      {selectedRunbook === "Metric Review" && (
        <>
          <Checkbox
            label="Cluster must be M30 or higher"
            checked={fieldConfig[selectedRunbook].isM30orHigher.value as boolean}
            onChange={() => handleFieldChange("isM30orHigher", !fieldConfig[selectedRunbook].isM30orHigher.value)}
          />
          <Checkbox
            label="Customer requested the Review, or confirmed that they have a problem"
            checked={fieldConfig[selectedRunbook].realPain.value as boolean}
            onChange={() => handleFieldChange("realPain", !fieldConfig[selectedRunbook].realPain.value)}
          />
          <br   />
          <TextInput
            className="fieldMargin"
            baseFontSize={13}
            label="Cluster Name *"
            value={fieldConfig[selectedRunbook].clusterName.value as string}
            onChange={(e) => handleFieldChange("clusterName", e.target.value)}
            placeholder="Cluster name"
          />
          <TextInput
            className="fieldMargin"
            baseFontSize={13}
            label="Atlas Project Link *"
            optional={false}
            value={fieldConfig[selectedRunbook].atlasProjectLink.value as string}
            onChange={(e) => handleFieldChange("atlasProjectLink", e.target.value)}
            placeholder="Atlas Project link"
          />
          <TextInput
            className="fieldMargin"
            baseFontSize={13}
            label="Success Plan Deck Link"
            optional={true}
            value={fieldConfig[selectedRunbook].successPlan.value as string}
            onChange={(e) => handleFieldChange("successPlan", e.target.value)}
            placeholder="Sharable Google slides link for 10gen, not just the copy-pasted URL"
          />
        </>
      )}

      {selectedRunbook === "Backup Optimization" && (
        <>
          <Checkbox
            label="The cluster to optimize is at least an M40"
            checked={fieldConfig[selectedRunbook].isM40orHigher.value as boolean}
            onChange={() => handleFieldChange("isM40orHigher", !fieldConfig[selectedRunbook].isM40orHigher.value)}
          />
          <Checkbox
            label="Customer's backup represents more than 40% of the monthly cost"
            checked={fieldConfig[selectedRunbook].csmOpenedTicket.value as boolean}
            onChange={() => handleFieldChange("csmOpenedTicket", !fieldConfig[selectedRunbook].csmOpenedTicket.value)}
          />
          <Checkbox
            label="Account Team is aware that this will evidently reduce the ARR"
            checked={fieldConfig[selectedRunbook].churnRisk.value as boolean}
            onChange={() => handleFieldChange("churnRisk", !fieldConfig[selectedRunbook].churnRisk.value)}
          />
          <br   />
          <TextInput
            className="fieldMargin"
            baseFontSize={13}
            label="Cluster Name *"
            value={fieldConfig[selectedRunbook].clusterName.value as string}
            onChange={(e) => handleFieldChange("clusterName", e.target.value)}
            placeholder="Cluster name"
          />
          <TextInput
            className="fieldMargin"
            baseFontSize={13}
            label="Atlas Project Link *"
            optional={false}
            value={fieldConfig[selectedRunbook].atlasProjectLink.value as string}
            onChange={(e) => handleFieldChange("atlasProjectLink", e.target.value)}
            placeholder="Atlas project link"
          />
        </>
      )}

      {selectedRunbook === "Cluster Optimization" && (
        <>
          <Checkbox
            label="Customer is prepared to run the mSizer script"
            checked={fieldConfig[selectedRunbook].mSizerReader.value as boolean}
            onChange={() => handleFieldChange("mSizerReader", !fieldConfig[selectedRunbook].mSizerReader.value)}
          />
        </>
      )}
      {selectedRunbook === "Technical Success Plan" && (
        <>
          <Checkbox
            label="The Success Plan has been completed by the CSM"
            checked={fieldConfig[selectedRunbook].successPlanDone.value as boolean}
            onChange={() => handleFieldChange("successPlanDone", !fieldConfig[selectedRunbook].successPlanDone.value)}
          />
        </>
      )}
      {selectedRunbook === "Support Go-Live Loopback" && (
        <>
          <Checkbox
            label="This is a Standard or Premium Workload"
            checked={fieldConfig[selectedRunbook].standardOrPremium.value as boolean}
            onChange={() => handleFieldChange("standardOrPremium", !fieldConfig[selectedRunbook].standardOrPremium.value)}
          />
        </>
      )}
    <br   />
    <hr />
    </div>
  );
};

export default EngagementsPrerequisites;
