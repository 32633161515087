// RunbookSelector.tsx// RunbookSelector.tsx
import React, { useEffect } from 'react';
import { Option, Select, OptionGroup } from "@leafygreen-ui/select";
import Banner from "@leafygreen-ui/banner";
import { Label, Description } from "@leafygreen-ui/typography";
import EngagementsPrerequisites from './EngagementsPrerequisites'; // Import the new component

interface RunbookSelectorProps {
  selectedEngagement: string;
  setRunbook: (value: string) => void;
  runbook: string;
  registerValidation: (key: string, validateFunc: () => ValidationError) => void;
  unregisterValidation: (key: string) => void;
  setPostDescription: (description: string) => void;
}

interface ValidationError {
  validation: boolean;
  message: string;
}

// ------------------------------------------------------------------------
// ------ Runbook Description
// ------------------------------------------------------------------------

const runbookDescriptions: { [key: string]: string } = {
  "Metric Review": "Analyzing our customer’s Atlas cluster performance and health. Detect and explain issues, and provide recommendations.Not a discovery call.",
  "Backup Optimization": "Evaluating and suggesting changes in backup policy to help our customers control their spending on backups and to understand where the cost is coming from.",
  "Cluster Optimization": "Provide a comprehensive analysis of the findings and recommendations from running the mSizer script on MongoDB clusters. This should include a detailed evaluation of the current cluster workload, resource utilization, and opportunities for optimization.",
  "Technical Success Plan": "Understanding the technical goals and anticipating challenges or roadblocks that might appear when trying to onboard the customer and get the workload to production.",
  "Support Go-Live Loopback": "Support customers post go-live, ensuring that Required Capabilities have been met and that there are no blockers/issues in MongoDB adoption.",
  // Add more descriptions as needed
};

// ------------------------------------------------------------------------

const RunbookSelector: React.FC<RunbookSelectorProps> = ({ setPostDescription, selectedEngagement, runbook, setRunbook, registerValidation, unregisterValidation }) => {
  useEffect(() => {
    // Register and unregister `RunbookSelector`-specific validation, if needed.
    registerValidation("RunbookSelector", validateRunbookSelector);
    console.log("MOUNTING RunbookSelector");
    return () => { // Unmounting: clean & Reset runbook to an empty string on unmount
      unregisterValidation("RunbookSelector");
      console.log("Unmounting RunbookSelector");
      setRunbook(null); 
    };}, []);

  const validateRunbookSelector = (): ValidationError => {
    if (runbook === null) return { validation: false, message: "Please select a runbook." };
    return {validation: true, message: ""};
  };

  return (
    <>
      <Banner variant="warning" className="bannerStyle fieldMargin">
        <strong>Ongoing Pilot - limited access to runbooks</strong><br /><br />
        Runbooks opened to every CSM
        <ul>
            <li>None as of now</li>
        </ul>
        Runbooks opened only to the 34 Pilot members only
        <ul>
            <li>Technical Success Plan</li>
            <li>Support post Go-live Loopback</li>
            <li>Atlas Metric Review</li>
            <li>Backup Optimization</li>
            <li>Cluster Optimization</li>
        </ul>
      </Banner> 

      <Label className="fieldMargin" id="description-label" htmlFor="descriptionTextArea">
        Runbook *
      </Label>
      <Description>
        Find detailed explanations for our engagements on{' '}
        <a
          href="https://wiki.corp.mongodb.com/display/10GEN/CS+x+RSC%3A+Runbook+Master+list"
          target="_blank"
          rel="noopener noreferrer"
        >
          this page
        </a>.
      </Description>
      <Select
        className="fieldMargin"
        label=""
        name="runbook"
        baseFontSize={13}
        onChange={(selectedValue) => setRunbook(selectedValue)}
      >
        <OptionGroup label="ONBOARDING">
        <Option value="Technical Success Plan">Technical Success Plan</Option>
        <Option value="Support Go-Live Loopback">Support post Go-live Loopback</Option>
        </OptionGroup>
        <OptionGroup label="CONSUMPTION">
          <Option value="Metric Review">Atlas Metrics Review</Option>
          <Option value="Backup Optimization">Backup Optimization</Option>
          <Option value="Cluster Optimization">Cluster Optimization</Option>
        </OptionGroup>
      </Select>
      {runbook && (
        <>
          <Banner variant="info" className="bannerStyle fieldMargin">
            <strong>{runbook}</strong>
            <br />
            <br />
            {runbookDescriptions[runbook]}
          </Banner>
          <EngagementsPrerequisites
            selectedRunbook={runbook}
            registerValidation={registerValidation}
            unregisterValidation={unregisterValidation}
            setPostDescription={setPostDescription}
          />
        </>
      )}
    </>
  );
};

export default RunbookSelector;